import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components/macro";
import { Container } from "../common/styled-components";
import Layout from "../components/Layout";
import { Establishment } from "../components/Establishment";
import get from "lodash/get";

import { IoIosArrowRoundBack } from "react-icons/io";
import CopyText from "../components/CopyText";
import axios from "axios";
import marked from "marked";
import { lgBtnClick } from "../common/firebaseAnalytics";

const NewsPostPage = ({ location }) => {
  const [post, setPost] = useState(null);
  const [contactIsOpen, setContactIsOpen] = useState(false);

  const postId = location.search.replace("?", "");

  const createShareURL = (postId) => {
    return `https://takeitaway.today/post?${postId}`;
  };

  const fetchPost = async () => {
    try {
      // setIsLoading(true);
      const result = await axios.get(
        `/.netlify/functions/newsPostOneEntry?postId=${postId}`
      );
      setPost(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <Layout>
      <Container>
        {post && (
          <Post>
            <Wrapper>
              <Back to="/">
                <IoIosArrowRoundBack /> To other posts
              </Back>
              <PostHead>
                <Image src={post.postImage.fields.file.url}></Image>
              </PostHead>
              <PostBody>
                <Title>
                  {post.businessName} | {post.title}
                </Title>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: marked(get(post, "description") || ""),
                  }}
                />
                <ServicedAreas>
                  <InfoLabel>Available in </InfoLabel>
                  {post.areasOfAvailability.map((area, index) => (
                    <Area key={index}>{area}</Area>
                  ))}
                </ServicedAreas>
                <SharingOptions>
                  <CopyText
                    textToCopy={createShareURL(postId)}
                    buttonText={"Get a shareable link"}
                    notificationText={"Link copied !"}></CopyText>
                </SharingOptions>
                {post.businessId && (
                  <ContactButton
                    onClick={() => {
                      setContactIsOpen(!contactIsOpen);
                      lgBtnClick("post_contact_details", { postId: postId });
                    }}>
                    Contact Details
                  </ContactButton>
                )}
                {contactIsOpen && (
                  <EstablishmentWrapper>
                    <Establishment
                      key={postId}
                      contactNumber={post.businessId.fields.contactNumber}
                      address={post.businessId.fields.address}
                      googleMapsLink={post.businessId.fields.googleMapsLink}
                      pickup={post.businessId.fields.doesPickup}
                      delivery={post.businessId.fields.doesDelivery}
                      website={post.businessId.fields.websiteUrl}
                      instagram={post.businessId.fields.instagramUrl}
                      facebook={post.businessId.fields.facebookUrl}
                      uberEats={post.businessId.fields.uberEatsUrl}
                      deliveroo={post.businessId.fields.deliverooUrl}
                      menuLog={post.businessId.fields.menuLogUrl}
                      heyYou={post.businessId.fields.heyYouUrl}
                      doorDash={post.businessId.fields.doorDashUrl}
                      bopple={post.businessId.fields.boppleUrl}
                    />
                  </EstablishmentWrapper>
                )}
              </PostBody>
              {post.externalPostLink && (
                <ExternalPostTab
                  href={post.externalPostLink}
                  onClick={() =>
                    lgOutBndLink("post_find_out_more", { postId: postId })
                  }
                  target="_blank">
                  Find out more
                </ExternalPostTab>
              )}
            </Wrapper>
          </Post>
        )}
      </Container>
    </Layout>
  );
};

export default NewsPostPage;

const Post = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
`;

const Back = styled(Link)`
  margin-top: 20px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:link {
    color: black;
  }

  &:visited {
    color: black;
  }

  & svg {
    height: 2rem;
    width: 2rem;
  }
`;

const PostHead = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: 100%;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
    border-radius: 10px 10px 0 0;
  }
`;

const PostBody = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 10px;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 2rem;
  }
`;

const Description = styled.div`
  & p {
    white-space: pre-line;
  }

  & img {
    width: 100%;
  }
`;

const ServicedAreas = styled.dl`
  font-size: 1.4rem;
  flex-basis: 50%;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
  }
`;

const InfoLabel = styled.dt`
  margin-bottom: 5px;
`;

const Area = styled.dd`
  display: inline;
  margin-left: 0;
  margin-right: 10px;
`;

const SharingOptions = styled.ul`
  padding: 0;
  flex-basis: 50%;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
  }
`;

const ContactButton = styled.button`
  outline: none;
  border: 2px solid black;
  background-color: white;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
`;

const ExternalPostTab = styled.a`
  padding: 10px;
  flex-basis: 100%;
  background-color: black;
  border-radius: 0 0 10px 10px;
  color: white;
  text-align: center;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: uppercase;
`;

const EstablishmentWrapper = styled.div`
  margin-top: 10px;
  flex-basis: 100%;
`;
