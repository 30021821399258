import React, { useState } from "react";
import styled from "styled-components/macro";
import { FaLink } from "react-icons/fa";

const CopyText = ({ textToCopy, buttonText, notificationText }) => {
  const [isCopied, setIsCopied] = useState(false);

  const toggleIsCopied = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <>
      <ShareText
        onClick={() => {
          toggleIsCopied();
          navigator.clipboard.writeText(textToCopy);
        }}>
        {buttonText}
        <FaLink />
      </ShareText>
      <CopyNotification active={isCopied}>{notificationText}</CopyNotification>
    </>
  );
};

export default CopyText;

const ShareText = styled.button`
  outline: none;
  border: 2px solid black;
  background-color: white;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

const CopyNotification = styled.div`
  font-size: 1.4rem;
  background-color: #bebebe;
  line-height: 2.1rem;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  border-radius: 5px;
  color: white;
  opacity: ${(props) => (props.active ? 1 : 0)};

  transition-duration: 0.2s;
`;
